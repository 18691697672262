<template>
  <div class="lg:p-2 w-full overflow-x-auto md:h-full h-full relative no-scrollbar">
    <div class="flex justify-between items-center mb-4 relative">
      <div class="font-medium text-[#d43a65] mb-4">
        <h1 class="py-3 text-lg lg:text-3xl text-black font-bold">
          {{ $t('library').capitalize() }}
        </h1>
        <p class="text-xs lg:text-lg">{{ $t('amount of lessons').capitalize() }}:
          <span>{{ useLibraryStore.pagination.total }}</span></p>
      </div>
      <button-component class="hidden md:block"
                        :disabled="useLibraryStore.categories.length <= 0 || useLibraryStore.isLoadingBooks"
                        buttonText="create lesson"
                        buttonColor="action" v-if="useAuthStore.permissions?.includes('books_create')"
                        @click="openCreateModal()"/>
    </div>
    <div class="grid lg:grid-cols-8 gap-4 relative">
      <filter-component
          v-if="useLibraryStore.filters"
          :is-disabled="useLibraryStore.isLoadingBooks"
          :filters="useLibraryStore.filters"
          class="hidden lg:block xl:col-span-2 lg:col-span-3 md:col-span-3 col-span-0 border-2 rounded-3xl border-[#ECEAE3] shadow-sm p-4 flex-1 overflow-y-auto lg:h-[calc(100vh-300px)] pb-[100px]"
      />
      <div class="xl:col-span-6 lg:col-span-5 md:col-span-3 sm:col-span-2 col-span-8">
        <div v-if="!useLibraryStore.isLoadingBooks">
          <div
              class="grid xl:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 lg:p-4 overflow-y-auto lg:max-h-[calc(100vh-400px)] md:max-h-[calc(100vh-300px)] max-h-[calc(100vh-400px)]"
              v-if="libraries.length > 0" @scroll="handleScroll">
            <card v-for="library in libraries" :key="library.uuid"
                  :class="['rounded transform transition duration-200 cursor-pointer']"
                  @edit="updateBook(library.uuid)"
                  @delete="confirmDeleteBook(library.uuid)"
                  @show="openBook($event, library)"
                  updatePermission="books_update"
                  deletePermission="books_delete"
                  showPermission="books_view"
                  :allow-edits="true"
                  :title="library.name || library.lesson_code"
                  :is-locked="isLocked(library.is_demo)"
                  :is-demo="!!library.is_demo"
                  :updated-at="library.updated_at"
                  :categories="library.categories"
                  :image="library.image"
                  :show-image="library.showImage"
                  :views="library.views"
                  title-size="sm"/>
          </div>
          <div v-else class="text-center pt-8">
            <icon-component icon="fi fi-br-empty-set" class="text-6xl text-FLregistration"/>
            <h1 class="text-xl">{{ $t('there are no lessons').capitalize() }}</h1>
          </div>
        </div>
        <div v-else
             class="grid lg:grid-cols-3 rounded md:grid-cols-3 sm:grid-cols-1 gap-6 p-4 overflow-y-auto md:max-h-[calc(100vh-370px)] max-h-[calc(100vh-370px)]">
          <div v-for="i in 3" :key="i">
            <SkeletonLoader :style="{ height: 'calc(20vh - 4.5rem)', width: '100%', borderRadius: '0.5rem'}"/>
          </div>
        </div>
        <div v-if="useLibraryStore.pagination.totalPages > 1"
             class="bottom-0 left-0 right-0 flex justify-center items-center lg:space-x-3 space-x-1 mt-8 lg:py-4 z-10">
          <button
              @click="changePage(Math.max(useLibraryStore.pagination.currentPage - 5, 1))"
              :disabled="useLibraryStore.pagination.currentPage <= 5"
              class="lg:px-4 lg:py-1 px-2 py-2 lg:p-0 w-8 h-8 lg:w-auto lg:h-auto bg-gray-200 hover:bg-gray-300 lg:rounded-xl rounded-lg disabled:opacity-50 font-bold lg:text-lg text-xs">
            <span class="lg:text-2xl text-xs">«</span>
          </button>
          <button
              @click="changePage(useLibraryStore.pagination.currentPage - 1)"
              :disabled="useLibraryStore.pagination.currentPage === 1"
              class="lg:px-4 lg:py-1 px-2 py-2 w-8 h-8 lg:w-auto lg:h-auto bg-gray-200 hover:bg-gray-300 lg:rounded-xl rounded-lg disabled:opacity-50 font-bold lg:text-lg text-xs">
            <span>‹</span>
          </button>
          <button
              v-for="page in pages"
              :key="page"
              @click="changePage(page)"
              :class="['lg:px-4 lg:py-1 px-2 py-2 w-8 h-8 lg:w-auto lg:h-auto lg:rounded-xl rounded-lg font-bold lg:text-lg text-xs', {
              'bg-blue-400 text-white': page === useLibraryStore.pagination.currentPage,
              'bg-gray-200 hover:text-white hover:bg-gray-300': page !== useLibraryStore.pagination.currentPage
            }]">
            {{ page }}
          </button>
          <button
              @click="changePage(useLibraryStore.pagination.currentPage + 1)"
              :disabled="useLibraryStore.pagination.currentPage === useLibraryStore.pagination.totalPages"
              class="lg:px-4 lg:py-1 px-2 py-2 w-8 h-8 lg:w-auto lg:h-auto bg-gray-200 hover:bg-gray-300 lg:rounded-xl rounded-lg disabled:opacity-50 font-bold lg:text-lg text-xs">
            <span>›</span>
          </button>
          <button
              @click="changePage(Math.min(useLibraryStore.pagination.currentPage + 5, useLibraryStore.pagination.totalPages))"
              :disabled="useLibraryStore.pagination.currentPage + 5 > useLibraryStore.pagination.totalPages"
              class="lg:px-4 lg:py-1 px-2 py-2 w-8 h-8 lg:w-auto lg:h-auto bg-gray-200 hover:bg-gray-300 lg:rounded-xl rounded-lg disabled:opacity-50 font-bold lg:text-lg text-xs">
            <span class="lg:text-2xl text-xs">»</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import Card from "@/components/LessonCardComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import FilterComponent from "@/views/library/components/FilterComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'OverviewBookComponent',
  components: {IconComponent, FilterComponent, ButtonComponent, Card},

  setup() {
    const useLibraryStore = libraryStore();
    const useAuthStore = authStore();
    const sweetAlertBook = ref(null);

    const perPageOptions = [
      {value: 12, text: '12'},
      {value: 24, text: '24'},
      {value: 36, text: '36'},
      {value: 48, text: '48'},
      {value: 60, text: '60'},
    ];

    const selectedPerPage = ref(useLibraryStore.pagination.perPage);

    /**
     * Shows a confirmation dialog before deleting a book.
     * @param {string} uuid - The UUID of the book to delete.
     */
    const confirmDeleteBook = (uuid) => {
      showDeleteConfirmation(() => deleteBook(uuid));
    };

    /**
     * Deletes a book by its UUID.
     * @param {string} uuid - The UUID of the book to delete.
     */
    const deleteBook = async (uuid) => {
      useLibraryStore.lastFetched = null;

      try {
        useLibraryStore.loading = true;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await useLibraryStore.deleteBook(uuid);
      } catch (error) {
        console.error('Error deleting book:', error);
      } finally {
        useLibraryStore.loading = false;
      }
    };

    /**
     * Updates the number of items per page and fetches the books.
     */
    const updatePerPage = async () => {
      useLibraryStore.pagination.perPage = selectedPerPage.value;
      useLibraryStore.pagination.currentPage = 1;
      useLibraryStore.lastFetched = null;
      await useLibraryStore.fetchAllBooks(1);
    };

    return {
      useLibraryStore,
      useAuthStore,
      sweetAlertBook,
      confirmDeleteBook,
      perPageOptions,
      selectedPerPage,
      updatePerPage,
    };
  },

  props: {
    libraries: {
      required: true,
      type: Array,
    },
  },

  computed: {
    /**
     * Computes the pages to be displayed in the pagination.
     * @returns {Array} The array of page numbers.
     */
    pages() {
      const totalPages = this.useLibraryStore.pagination.totalPages;
      const currentPage = this.useLibraryStore.pagination.currentPage;
      const pagesToShow = 5;
      const half = Math.floor(pagesToShow / 2);

      let start = currentPage - half;
      let end = currentPage + half;

      if (start < 1) {
        start = 1;
        end = pagesToShow;
      }
      if (end > totalPages) {
        end = totalPages;
        start = totalPages - pagesToShow + 1;
        if (start < 1) start = 1;
      }

      return Array.from({length: end - start + 1}, (v, k) => start + k);
    },
  },

  methods: {
    /**
     * Checks if a book is locked for demo users.
     * @param {boolean} isDemo - Indicates if the book is a demo book.
     * @returns {boolean} True if the book is locked, false otherwise.
     */
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles[0]?.name === 'demo_user' && !isDemo;
    },

    /**
     * Opens a book and navigates to its detail page.
     * @param {Event} $e - The event object.
     * @param {Object} book - The book object.
     */
    async openBook($e, book) {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.selectedBook = book;
        this.navigateTo($e, '/library/book/' + book.uuid);
      } catch (error) {
        console.error('Error opening book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    /**
     * Changes the current page and fetches the books for that page.
     * @param {number} page - The page number to navigate to.
     */
    async changePage(page) {
      if (page !== this.useLibraryStore.pagination.currentPage) {
        this.useLibraryStore.lastFetched = null;
        this.useLibraryStore.pagination.currentPage = page;
        await this.useLibraryStore.fetchAllBooks(page, false);
      }
    },

    /**
     * Opens the modal to create a new book.
     */
    async openCreateModal() {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.resetForm();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = false;
      } catch (error) {
        console.error('Error opening create modal:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    /**
     * Updates a book by its UUID.
     * @param {string} uuid - The UUID of the book to update.
     */
    async updateBook(uuid) {
      try {
        this.useLibraryStore.loading = true;
        await this.useLibraryStore.fetchBook(uuid, false);
        await this.useLibraryStore.setFormBook();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = true;
      } catch (error) {
        console.error('Error updating book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },
  },
};
</script>