<template>
  <div class="p-2 w-full overflow-x-auto md:h-[calc(100vh-140px)] relative">
    <div class="flex justify-between items-center mb-4">
      <div class="font-medium text-[#d43a65] mb-4">
        <div class="md:py-3 text-lg lg:text-3xl text-black font-bold">
          {{ $t('users').capitalize() }}
        </div>
        <p class="text-xs lg:text-lg">{{ $t('amount of users').capitalize() }}: <span>{{ totalUsers }}</span></p>
      </div>
      <div>
        <button-component v-if="useAuthStore.permissions.includes('users_create')" buttonColor="action"
                          buttonText="Add User" class="hidden md:block"
                          @click="openCreateModal"/>
      </div>
    </div>
    <div
        class="border-2 text-xs lg:text-lg rounded-xl lg:rounded-3xl border-[#ECEAE3] shadow-sm lg:p-12 p-6 flex-1 overflow-y-auto md:h-[calc(60vh-20px)] no-scrollbar">
      <table class="min-w-full border-collapse">
        <tbody>
        <tr v-for="workspace in data.workspaces" :key="workspace.uuid">
          <td class="py-1" colspan="5">
            <div class="cursor-pointer bg-gray-100 py-4 px-4 flex rounded-xl justify-between items-center" @click="toggleDropdown(workspace.uuid)">
              <div class="text-black flex items-center">
                <div>{{ workspace.name }}</div>
                <icon-component
                    :icon="isDropdownOpen(workspace.uuid) ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                    class="ml-2 mt-1 text-sm"
                />
              </div>
              <span class="text-[#d43a65] md:block hidden text-xs lg:text-sm font-medium">
                  {{ $t('amount of users').capitalize() }}: {{ workspace.users.length }}
                </span>
            </div>

            <div v-if="isDropdownOpen(workspace.uuid)" class="mt-3">
              <table class="w-full mt-3">
                <thead>
                <tr>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('first name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('last name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('email').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('role').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('member since').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('actions').capitalize() }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in workspace.users" :key="user.uuid" class="hover:bg-gray-50 transition duration-200">
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.first_name }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.last_name }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.email }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">
                    {{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                  </td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ formatDate(user.created_at) }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm space-x-2">
                    <button v-if="useAuthStore?.user?.uuid !== user?.uuid &&
                                                      (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher')"
                            @click="confirmDeleteUser(user?.uuid)"
                            class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150">
                      <i class="fas fa-trash-alt"></i>
                    </button>

                    <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                            @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                            class="inline-flex items-center text-cta hover:text-ctalight  transition duration-150">
                      <icon-component icon="fi fi-sr-up"></icon-component>
                    </button>

                    <button v-if="user?.roles && user?.roles[0]?.name === 'school_director' &&
                                useAuthStore?.user?.roles?.[0]?.name === 'reseller_admin' &&
                                useAuthStore?.user?.uuid !== user?.uuid"
                            @click="confirmUpdateUser(user?.uuid)"
                            class="inline-flex items-center text-cta hover:text-ctalight transition duration-150">
                      <icon-component icon="fi fi-sr-down"></icon-component>
                    </button>

                    <button v-if="useAuthStore?.user?.workspace?.[0]?.books?.length > 0 &&
                                    (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher') &&
                                    user?.workspace && user.workspace?.length > 0 &&
                                    useAuthStore?.user?.workspace?.[0]?.uuid !== user.workspace?.[0]?.uuid"
                            @click="openLinkBooksModal(user.workspace?.[0]?.uuid, user.workspace?.[0]?.books)"
                            class="inline-flex items-center text-cta hover:text-ctalight  mx-2 transition duration-150">
                      <i class="fi fi-sr-book-alt"></i>
                    </button>

                    <button v-if="useAuthStore?.user?.workspace?.[0]?.programs?.length > 0 &&
                                    (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher') &&
                                    user?.workspace && user.workspace?.length > 0 &&
                                    useAuthStore?.user?.workspace?.[0]?.uuid !== user.workspace?.[0]?.uuid"
                            @click="openLinkProgramsModal(user.workspace?.[0]?.uuid, user.workspace?.[0]?.programs)"
                            class="inline-flex items-center text-cta hover:text-ctalight  mx-2 transition duration-150">
                      <i class="fi fi-sr-calendar-lines"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>

        <tr v-if="data.usersWithoutWorkspace.length > 0" :key="'no-workspace'">
          <td class="py-1" colspan="5">
            <div class="cursor-pointer bg-gray-100 py-4 px-4 rounded-xl flex justify-between items-center" @click="toggleDropdown('no-workspace')">
              <div class="text-black flex items-center">
                {{ $t('users without workspace').capitalize() }}
                <icon-component
                    :icon="isDropdownOpen('no-workspace') ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                    class="ml-2 mt-1 text-sm"
                />
              </div>
              <span class="text-[#d43a65] md:block hidden text-[10px] text-xs lg:text-sm  font-medium">
                  {{ $t('amount of users').capitalize() }}: {{ data.usersWithoutWorkspace.length }}
                </span>
            </div>

            <div v-if="isDropdownOpen('no-workspace')" class="mt-3">
              <table class="w-full mt-3">
                <thead>
                <tr>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('first name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('last name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('email').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('role').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('member since').capitalize() }}
                  </th>
                  <th class="w-1/6 px-2 lg:px-5 lg:py-2 py-1 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('actions').capitalize() }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in data.usersWithoutWorkspace" :key="user.uuid"
                    class="hover:bg-gray-50 transition duration-200">
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.first_name }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.last_name }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.email }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                  </td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm">{{ formatDate(user.created_at) }}</td>
                  <td class="px-2 lg:px-5 lg:py-2 py-1 text-[10px] lg:text-sm space-x-2">
                    <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                            @click="confirmDeleteUser(user?.uuid)"
                            class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150">
                      <i class="fas fa-trash-alt"></i>
                    </button>

                    <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                            @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                            class="inline-flex items-center text-cta hover:text-ctalight  transition duration-150">
                      <icon-component icon="fi fi-sr-up"></icon-component>
                    </button>

                    <button v-if="user?.roles && user?.roles[0]?.name === 'school_director'"
                            @click="confirmUpdateUser(user?.uuid)"
                            class="inline-flex items-center text-cta hover:text-ctalight  transition duration-150">
                      <icon-component icon="fi fi-sr-down"></icon-component>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr v-if="data.demoUsers.length > 0" :key="'demo-users'">
          <td class="py-1" colspan="5">
            <div class="cursor-pointer bg-gray-100 py-4 px-4 rounded-xl flex justify-between items-center" @click="toggleDropdown('demo-users')">
              <div class="text-black flex items-center">
                <strong>{{ $t('demo users').capitalize() }}</strong>
                <icon-component
                    :icon="isDropdownOpen('demo-users') ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                    class="ml-2 mt-1 text-sm"
                />
              </div>
              <span class="text-[#d43a65] text-sm font-medium">
                {{ $t('amount of users').capitalize() }}: {{ data.demoUsers.length }}
              </span>
            </div>

            <div v-if="isDropdownOpen('demo-users')" class="mt-3">
              <table class="w-full mt-3">
                <thead>
                <tr>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('first name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('last name').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('email').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('role').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('function').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('member since').capitalize() }}
                  </th>
                  <th class="w-1/6 px-5 py-2 text-left text-xs font-semibold text-[#4BB4F1] uppercase tracking-wider">
                    {{ $t('actions').capitalize() }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in data.demoUsers" :key="user.uuid" class="hover:bg-gray-50 transition duration-200">
                  <td class="w-1/6 px-5 py-2 text-sm">{{ user.first_name }}</td>
                  <td class="w-1/6 px-5 py-2 text-sm">{{ user.last_name }}</td>
                  <td class="w-1/6 px-5 py-2 text-sm">{{ user.email }}</td>
                  <td class="w-1/6 px-5 py-2 text-sm">
                    {{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                  </td>
                  <td class="w-1/6 px-5 py-2 text-sm">{{ checkFunctionTranslation(user?.demo_form?.function) }}</td>
                  <td class="w-1/6 px-5 py-2 text-sm">{{ formatDate(user.created_at) }}</td>
                  <td class="w-1/6 px-5 py-2 text-sm space-x-2">
                    <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                            class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150"
                            @click="confirmDeleteUser(user?.uuid)">
                      <i class="fas fa-trash-alt"></i>
                    </button>

                    <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                            @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                            class="inline-flex items-center text-cta hover:text-ctalight transition duration-150">
                      <icon-component icon="fi fi-sr-up"></icon-component>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!useUserStore.showEditUserModal" class="md:hidden mb-20 fixed bottom-4 left-4 right-4 z-40">
      <button-component v-if="useAuthStore.permissions.includes('users_create')"
                        :always-show-text="true"
                        :disabled="useUserStore.isLoading"
                        :ghost="true"
                        buttonColor="action"
                        buttonText="Add User"
                        class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
                        @click="openCreateModal"
      />
    </div>
  </div>
</template>

<script>
import {computed, reactive} from "vue";
import {userStore} from "@/views/users/stores/user.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import {showCustomAlert, showDeleteConfirmation} from "@/helpers/sweetAlert";
import {i18n} from "@/i18n";

export default {
  name: "OverviewUserComponent",
  components: {ButtonComponent, IconComponent},

  setup() {
    const useUserStore = userStore();
    const useAuthStore = authStore();
    const openDropdowns = reactive({});

    const toggleDropdown = (id) => (openDropdowns[id] = !openDropdowns[id]);
    const isDropdownOpen = (id) => openDropdowns[id];

    const totalUsers = computed(() => {
      return (
          useUserStore.categorizedUsers.workspaces.reduce((count, ws) => count + ws.users.length, 0) +
          useUserStore.categorizedUsers.usersWithoutWorkspace.length +
          useUserStore.categorizedUsers.demoUsers.length
      );
    });

    return {
      useUserStore,
      useAuthStore,
      data: useUserStore.categorizedUsers,
      toggleDropdown,
      isDropdownOpen,
      totalUsers
    };
  },
  methods: {
    /**
     * Opens the modal to create a new user.
     */
    checkFunctionTranslation(userFunction){
      if(userFunction === "intern supervisor" || userFunction === "adjunct director" || userFunction === "school leader" || userFunction === "teacher" || userFunction === "upper school board" || userFunction === "working group with decision-making authority" || userFunction === "else"){
        return i18n.t(userFunction);
      } else {
        return userFunction;
      }
    },

    openCreateModal() {
      this.useUserStore.showEditUserModal = true;
      this.useUserStore.editMode = false;
    },
    openLinkBooksModal(workspaceUuid, selectedUserBooks) {
      this.useUserStore.selectedWorkspaceBooks = selectedUserBooks;
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid;
      this.useUserStore.showEditUserBooksModal = true;
      this.useUserStore.editUserBooksMode = false;
    },
    openLinkProgramsModal(workspaceUuid, selectedUserPrograms) {
      this.useUserStore.selectedWorkspacePrograms = selectedUserPrograms;
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid;
      this.useUserStore.showEditUserProgramsModal = true;
      this.useUserStore.editUseProgramsMode = false;
    },
    async confirmDeleteUser(userUuid) {
      showDeleteConfirmation(() => this.deleteUser(userUuid));
    },
    /**
     * Deletes a user.
     * @param {string} userUuid - The UUID of the user to delete.
     */
    async deleteUser(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.deleteUser();
    },
    async confirmUpdateUser(userUuid, userRequested) {
      showCustomAlert(() => this.updateUserRole(userUuid), 'update user', 'are you sure you want to update this user?', {}, {}, 'success', 'successfully updated', {}, {}, userRequested ? 'deny request' : null, userRequested ? () => this.denyUserRequest(userUuid) : null);
    },
    async updateUserRole(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.updateUserRole();
    },
    async denyUserRequest(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.denyUserRequest();
    },
    formatDate(date) {
      if (!date) return "-";
      return new Intl.DateTimeFormat("nl-NL", {year: "numeric", month: "long", day: "numeric"}).format(new Date(date));
    },
  },
};
</script>