<template>
  <div
      v-if="useAgendaStore.showEditProgramModal"
      class="absolute md:fixed z-50 inset-0 bg-gray-600 bg-opacity-50 bottom-0 overflow-y-auto md:h-full flex justify-center items-center"
      @click.self="closeModal">

    <div class="relative mx-auto p-5 border md:w-3/5 w-full shadow-lg rounded-md bg-white md:h-[calc(115vh-200px)]">
      <div class="absolute -top-4 -right-4 transform">
        <button @click="closeModal" class="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-pink-500 hover:bg-pink-100 border-2 border-white">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-lg leading-6 font-bold text-gray-900 mb-5" v-if="!useAgendaStore.editMode">{{$t('create a new program').capitalize()}}</h3>
      <h3 class="text-lg leading-6 font-bold text-gray-900 mb-5" v-else>{{$t('update program').capitalize()}}</h3>

      <form @submit.prevent="submitProgram()" class="pt-2 font-bold">
        <input-component label="naam" class="mb-5" v-model="useAgendaStore.form.name" input-type="text" placeholder="program name" inputColor="primary" :required="true"/>
        <textarea-component label="domain" v-model="useAgendaStore.form.description" placeholder="" inputColor="secondary" :required="true"/>
        <h1>{{$t('choose your lessons').capitalize()}}</h1>

        <div class="border-2 rounded-3xl border-[#ECEAE3] col-span-2 p-4 overflow-y-auto h-80">
          <checkbox-component v-for="book in books"
                              :checked="useAgendaStore?.form.book_uuids?.includes(book.uuid)"
                              @update:checked="checkBook(book.uuid, $event)"
                              :label="book.name ? book.lesson_code + ' (' + book.name + ')' : book.lesson_code"
                              :fieldId="book.uuid"
                              :key="book"
                              v-model="useAgendaStore.form.book_uuids"/>
        </div>
        <button-component :buttonText="useAgendaStore.editMode ? 'update' : 'submit'" buttonColor="action" prefix-icon="fi fi-rr-paper-plane-top"   class="mt-4 sm:w-full md:w-fit md:ml-0"  :is-loading="useAgendaStore.loadEditProgram"/>
      </form>
    </div>
  </div>
</template>

<script>
import { agendaStore } from "@/views/agenda/stores/agenda.store.js";
import { libraryStore } from "@/views/library/stores/library.store.js";
import InputComponent from "@/components/forms/InputComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue";

export default {
  name: 'EditAgendaViewComponent',
  components: { ButtonComponent, InputComponent, TextareaComponent, CheckboxComponent },
  setup() {
    const useAgendaStore = agendaStore();
    const useLibraryStore = libraryStore();
    return {
      useAgendaStore,
      useLibraryStore
    };
  },
  data() {
    return {
      books: []
    };
  },
  mounted() {
    this.getBooks();
  },
  methods: {
    async checkBook(bookUuid, checked) {
      let storeSelectedBooks = this.useAgendaStore.form.book_uuids;
      if (checked) {
        storeSelectedBooks.push(bookUuid);
      } else {
        storeSelectedBooks.splice(storeSelectedBooks.indexOf(bookUuid), 1);
      }
    },
    async submitProgram() {
      this.useAgendaStore.loadEditProgram = true;
      if (this.useAgendaStore.editMode) {
        await this.useAgendaStore.updateProgram();
      } else {
        await this.useAgendaStore.createProgram();
      }
    },
    async getBooks() {
      await this.useLibraryStore.fetchAllBooks(null, 'program');
      this.books = this.useAgendaStore.books;
    },
    closeModal() {
      this.useAgendaStore.showEditProgramModal = false;
    }
  }
};
</script>