<template>
  <div class="bg-white flex flex-col sm:flex-row h-screen relative">
    <div class="md:w-1/2 sm:w-full flex flex-col items-center p-4 sm:p-8 relative mt-16 sm:mt-32 z-10 mobile-center">
      <form class="items-center my-auto" @submit.prevent="submitForgotPassword">
        <h1 class="text-2xl mb-2 sm:mb-4 text-center sm:text-center">
          {{ $t('reset password').capitalize() }}</h1>

        <p class="text-md text-center">
          {{ $t('Je ontvangt een e-mail voor het resetten van je wachtwoord').capitalize() }}</p>

        <div class="items-center">
          <div class="mx-auto">
            <input-component v-model="store.formPasswordReset.email" type="email" placeholder="E-mail"
                             inputColor="primary" class="w-full mx-auto mb-2 sm:mb-4"/>
            <button-component type="submit" buttonText="Verzenden" class="w-full md:w-1/2 mx-auto mb-2"
                              buttonColor="action" :always-show-text="true"/>
            <p class="text-center text-md sm:text-lg text-gray-500">
              <RouterLink to="/login" class="underline text-black">{{ $t('cancel').capitalize() }}</RouterLink>
            </p>
          </div>
        </div>
      </form>

      <div class="mt-auto mb-4 px-4 flex justify-start space-x-4 w-full">
        <button @click="showPrivacyPolicy = true" class="md:text-md text-sm text-gray-500 underline">
          {{ $t('privacy policy').capitalize() }}
        </button>

        <button @click="showTermsOfService = true" class="md:text-md text-sm text-gray-500 underline">
          {{ $t('terms and conditions').capitalize() }}
        </button>
      </div>
    </div>

    <div class="hidden xl:block w-full h-full fixed xl:-right-32 lg:-right-72 top-0 z-0">
      <img src="../../assets/thumbnail_fluitendleren.png" alt="Girl Holding Book"
           class="w-full h-full object-cover object-right">
    </div>

    <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false"/>
    <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false"/>
  </div>
</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";

export default {
  name: 'RegisterComponent',
  components: {TermsOfService, InputComponent, ButtonComponent, PrivacyPolicy},
  data() {
    return {
      email: '',
      showPrivacyPolicy: false,
      showTermsOfService: false
    };
  },
  setup() {
    const store = authStore();
    return {
      store
    }
  },
  methods: {
    /**
     * Submits the forgot password request.
     * @returns {Promise<void>}
     */
    async submitForgotPassword() {
      this.store = authStore();
      try {
        await this.store.forgotPassword();
      } catch (error) {
        console.error("Error when sending email");
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 640px) {
  .mobile-center {
    justify-content: center;
    height: calc(100% - 40px);
  }
}
</style>