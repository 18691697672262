<template>
  <div class="w-full h-full overflow-x-auto relative">
    <div class="grid lg:grid-cols-3 grid-cols-1 h-full gap-8 md:gap-0">
      <div class="md:col-span-2">
        <div class="grid lg:grid-rows-3 gap-y-8 3xl:gap-y-12 4xl:gap-y-14">
          <div class="row-span-1">
            <h2 class="lg:text-2xl text-lg font-bold mb-6">Laatste Lessen</h2>

            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <card v-for="library in latestLibraries" :key="library.uuid"
                    :class="['w-full rounded transform transition duration-200 cursor-pointer']"
                    @show="openBook($event, library)"
                    :hide-dropdown="true"
                    :allow-edits="false"
                    :title="library.name || library.lesson_code"
                    :is-locked="isLocked(library.is_demo)"
                    :is-demo="!!library.is_demo"
                    :updated-at="library.updated_at"
                    :categories="library.categories"
                    :image="library.image"
                    :show-image="library.showImage"
                    :views="library.views"
                    title-size="sm"/>
            </div>
          </div>
          <div class="row-span-1 md:row-span-2">
            <h2 class="lg:text-2xl text-lg font-bold mb-6">Voor jou</h2>

            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <card v-for="library in foryouLibraries" :key="library.uuid"
                    :class="['w-full rounded transform transition mb-5 duration-200 cursor-pointer']"
                    @edit="updateBook(library.uuid)"
                    @delete="confirmDeleteBook(library.uuid)"
                    @show="openBook($event, library)"
                    :hide-dropdown="true"
                    updatePermission="books_update"
                    deletePermission="books_delete"
                    showPermission="books_view"
                    :allow-edits="true"
                    :title="library.name || library.lesson_code"
                    :is-locked="isLocked(library.is_demo)"
                    :is-demo="!!library.is_demo"
                    :updated-at="library.updated_at"
                    :categories="library.categories"
                    :image="library.image"
                    :show-image="library.showImage"
                    :views="library.views"
                    title-size="sm"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-1 space-y-4">
        <div class="md:h-1/2 md:p-8 md:border-2 border-default rounded-xl lg:rounded-3xl">
          <h2 class="text-lg lg:text-2xl font-bold mb-6">Updates</h2>

          <div class="flex items-start gap-4 mb-8">
            <p class="text-gray-900 text-sm lg:text-md my-auto">
              {{ $t('currently there are no updates').capitalize() }}
            </p>
          </div>
        </div>
        <div class="lg:h-fit md:p-8 md:border-2 md:border-default rounded-xl lg:rounded-3xl">
          <h2 class="lg:text-2xl text-lg font-bold mb-6">Programma's</h2>

          <div class="flex gap-4 mb-8 text-sm lg:text-md">
            <table class="w-full">
              <thead>
              <tr class="border-b-2 text-left">
                <th class="text-gray-600">{{ $t('title').capitalize() }}</th>
                <th class="text-gray-600">{{ $t('amount of lessons').capitalize() }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="program in programs" :key="program.title" @click.prevent="openProgram($event, program)">
                <td class="hover:cursor-pointer hover:underline">{{ program.title }}</td>
                <td>{{ program.books.length }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store.js";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import {appStore} from "@/views/app.store";
import {useRouter} from "vue-router";
import {computed, onMounted} from "vue";
import Card from "@/components/LessonCardComponent.vue";

export default {
  name: "DashboardComponent",
  components: {
    Card,
  },

  /**
   * Setup function for the component.
   * @returns {Object} The reactive state and methods.
   */
  setup() {
    const useLibraryStore = libraryStore();
    const useAgendaStore = agendaStore();
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();

    /**
     * Opens a book and increments its view count.
     * @param {Event} event - The event object.
     * @param {Object} book - The book object.
     */
    const openBook = async (event, book) => {
      try {
        await useLibraryStore.incrementViews(book.uuid);
        router.push(`/library/book/${book.uuid}`);
      } catch (error) {
        console.error("Error opening book:", error);
      }
    };

    /**
     * Fetches the list of books for the dashboard.
     */
    const fetchBooks = async () => {
      useLibraryStore.pagination.perPage = 9;
      await useLibraryStore.fetchAllBooks(1, "dashboard");
    };

    /**
     * Fetches the list of programs.
     * @param {Number} amount - The number of programs to fetch.
     */
    const fetchPrograms = async (amount = 4) => {
      useAgendaStore.queryFilter = "amount=" + amount;
      await useAgendaStore.fetchAllPrograms();
    };

    /**
     * Computed property for the list of libraries.
     * @returns {Array} The list of libraries.
     */
    const libraries = computed(() =>
        useLibraryStore.dashboard_books.map((book) => ({
          uuid: book.uuid,
          name: book.name,
          lesson_code: book.lesson_code,
          categories: book.categories,
          description: book.description,
          showImage: true,
          image: book.image,
          is_demo: book.is_demo,
          views: book.views || 0,
        }))
    );

    /**
     * Computed property for the latest libraries.
     * @returns {Array} The list of latest libraries.
     */
    const latestLibraries = computed(() => libraries.value.slice(0, 3));

    /**
     * Computed property for the libraries recommended for the user.
     * @returns {Array} The list of libraries recommended for the user.
     */
    const foryouLibraries = computed(() => libraries.value.slice(3, 9));

    /**
     * Computed property for the list of programs.
     * @returns {Array} The list of programs.
     */
    const programs = computed(() =>
        useAgendaStore.programs.map((program) => ({
          uuid: program.uuid,
          title: program.name,
          description: program.description,
          books: program.books,
        }))
    );

    /**
     * Opens a program.
     * @param {Event} event - The event object.
     * @param {Object} program - The program object.
     */
    const openProgram = (event, program) => {
      useAgendaStore.program = program;
      router.push(`/agenda/${program.uuid}`);
    };

    onMounted(() => {
      fetchBooks();
      fetchPrograms();
    });

    return {
      latestLibraries,
      foryouLibraries,
      openBook,
      useAuthStore,
      useAppStore,
      programs,
      openProgram,
    };
  },

  methods: {
    /**
     * Checks if the current user is a demo user.
     * @returns {Boolean} True if the user is a demo user, false otherwise.
     */
    isDemoUser() {
      return this.useAuthStore?.user?.roles?.[0]?.name === "demo_user";
    },

    /**
     * Checks if a book is locked for demo users.
     * @param {Boolean} isDemo - Indicates if the book is a demo book.
     * @returns {Boolean} True if the book is locked, false otherwise.
     */
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles?.[0]?.name === "demo_user" && !isDemo;
    },
  },
};
</script>