<template>
  <div class="w-full md:h-full h-[calc(100vh-150px)] overflow-x-auto md:relative">
    <div class="grid lg:grid-rows-[1fr_2fr] xl:h-full gap-6">
      <div class="xl:row-span-1 row-span-2">
        <div class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
          <div class="col-span-1">
            <vue-plyr class="w-full xl:h-fit">
              <div class="rounded-xl lg:rounded-3xl" data-plyr-provider="youtube"
                   data-plyr-embed-id="UhSSunSR3qY"></div>
            </vue-plyr>
          </div>
          <div class="xl:col-span-2 col-span-1">
            <div class="border-[#ECEAE3] border-2 h-fit rounded-xl lg:rounded-3xl drop-shadow-sm p-8">
              <h1 class="xl:text-2xl text-md mb-2">{{ $t("hello").capitalize() }} {{
                  this.useAuthStore.user.first_name
                }},</h1>
              <p class="mb-3 xl:text-sm text-xs">
                {{
                  $t("Welcome to the Online Library of Fluitend Leren.  Here you will find Moving Learning lessons for groups 1 to 8. Would you like to use Moving Learning structurally in the language and mathematics teaching of your primary school, without any extra workload?")
                }}</p>
              <button-component
                  :always-show-text="true"
                  button-text="get customized lessons"
                  buttonColor="action"
                  class="w-full"
                  @click="useAuthStore?.user?.request_upgrade ? useAppStore.showUpgradeFormModal = false : useAppStore.showUpgradeFormModal = true"/>
            </div>
          </div>
        </div>
      </div>
      <div class="md:row-span-2 row-span-1">
        <div class="grid lg:grid-cols-3 gap-6 xl:h-full">
          <div class="xl:col-span-2 col-span-3">
            <h2 class="xl:text-2xl text-lg font-bold mb-6 xl:mt-0 mt-6">Voor jou</h2>

            <div class="grid xl:grid-cols-3 lg:grid-cols-2 h-fit grid-cols-1 gap-4 overflow-y-auto">
              <card v-for="library in libraries" :key="library.uuid"
                    :allow-edits="true"
                    :categories="library.categories"
                    :hide-dropdown="true"
                    :image="library.image"
                    :is-demo="!!library.is_demo"
                    :is-locked="isLocked(library.is_demo)"
                    :show-image="library.showImage"
                    :title="library.name || library.lesson_code"
                    :updated-at="library.updated_at"
                    :views="library.views"
                    class="xl:h-[150px] rounded transform transition duration-200 cursor-pointer"
                    deletePermission="books_delete"
                    showPermission="books_view"
                    title-size="sm"
                    updatePermission="books_update"
                    @show="openBook($event, library)"/>
            </div>
          </div>
          <div class="xl:col-span-1 col-span-3 flex flex-col gap-4">
            <div class="flex-1 border-2 border-default p-8 rounded-xl lg:rounded-3xl">
              <h2 class="font-bold xl:text-2xl text-lg text-cta">{{ $t('get more').capitalize() }}</h2>
              <p class="text-black xl:text-xl text-md mb-2">{{ $t('350+ dynamic learning lessons').capitalize() }}</p>
              <ul class="mb-4 xl:text-md text-sm list-disc list-inside">
                <li>
                  {{ $t('customized for your school').capitalize() }}
                </li>
                <li>
                  {{ $t('grade 1 till 8').capitalize() }}
                </li>
                <li>
                  {{ $t('in line with the SLO').capitalize() }}
                </li>
                <li>
                  {{ $t('no extra workload').capitalize() }}
                </li>
              </ul>
              <button-component
                  :always-show-text="true"
                  button-text="book method presentation"
                  buttonColor="action"
                  class="w-full"
                  @click="
                useAuthStore?.user?.request_upgrade
                  ? useAppStore.showUpgradeFormModal = false
                  : useAppStore.showUpgradeFormModal = true
              "/>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store.js";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import {appStore} from "@/views/app.store";
import {useRouter} from 'vue-router';
import {computed, onMounted} from 'vue';
import Card from "@/components/LessonCardComponent.vue";
import VuePlyr from 'vue-plyr';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: 'DemoDashboardComponent',
  components: {
    ButtonComponent,
    Card,
    VuePlyr
  },

  /**
   * Setup function for the component.
   * @returns {Object} The reactive state and methods.
   */
  setup() {
    const useLibraryStore = libraryStore();
    const useAgendaStore = agendaStore();
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();

    /**
     * Opens a book and increments its view count.
     * @param {Event} event - The event object.
     * @param {Object} book - The book object.
     */
    const openBook = async (event, book) => {
      try {
        await useLibraryStore.incrementViews(book.uuid);
        router.push(`/library/book/${book.uuid}`);
      } catch (error) {
        console.error('Error opening book:', error);
      }
    };

    /**
     * Deletes a book by its UUID.
     * @param {String} uuid - The UUID of the book.
     */
    const deleteBook = async (uuid) => {
      try {
        await useLibraryStore.deleteBook(uuid);
      } catch (error) {
        console.error('Error deleting book:', error);
      }
    };

    /**
     * Updates a book by fetching its details.
     * @param {String} uuid - The UUID of the book.
     */
    const updateBook = async (uuid) => {
      try {
        await useLibraryStore.fetchBook(uuid);
      } catch (error) {
        console.error('Error updating book:', error);
      }
    };

    /**
     * Computed property for the list of libraries.
     * @returns {Array} The list of libraries.
     */
    const libraries = computed(() => useLibraryStore.dashboard_books.map(book => ({
      uuid: book.uuid,
      name: book.name,
      lesson_code: book.lesson_code,
      categories: book.categories,
      description: book.description,
      showImage: true,
      image: book.image,
      is_demo: book.is_demo,
      views: book.views || 0,
    })));

    /**
     * Fetches the list of books for the dashboard.
     */
    const fetchBooks = async () => {
      useLibraryStore.pagination.perPage = 6;
      await useLibraryStore.fetchAllBooks(1, 'dashboard');
    };

    /**
     * Fetches the list of programs.
     * @param {Number} amount - The number of programs to fetch.
     */
    const fetchPrograms = async (amount = 4) => {
      useAgendaStore.queryFilter = 'amount=' + amount;
      await useAgendaStore.fetchAllPrograms();
    };

    /**
     * Computed property for the list of programs.
     * @returns {Array} The list of programs.
     */
    const programs = computed(() => useAgendaStore.programs.map(program => ({
      uuid: program.uuid,
      title: program.name,
      description: program.description,
      books: program.books
    })));

    /**
     * Opens a program.
     * @param {Event} event - The event object.
     * @param {Object} program - The program object.
     */
    const openProgram = (event, program) => {
      useAgendaStore.program = program;
      router.push(`/agenda/${program.uuid}`);
    };

    onMounted(() => {
      fetchBooks();
      fetchPrograms();
    });

    return {
      libraries,
      openBook,
      deleteBook,
      useAuthStore,
      useAppStore,
      updateBook,
      programs,
      openProgram,
    };
  },

  methods: {
    /**
     * Checks if the current user is a demo user.
     * @returns {Boolean} True if the user is a demo user, false otherwise.
     */
    isDemoUser() {
      return this.useAuthStore?.user?.roles?.[0]?.name === 'demo_user';
    },

    /**
     * Checks if a book is locked for demo users.
     * @param {Boolean} isDemo - Indicates if the book is a demo book.
     * @returns {Boolean} True if the book is locked, false otherwise.
     */
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles?.[0]?.name === 'demo_user' && !isDemo;
    },
  }
};
</script>