<template>
  <div class="bg-transparent flex flex-col md:flex-row h-screen relative">
    <div class="xl:w-1/2 flex flex-col items-center md:p-8 mt-auto md:mt-0 relative z-10 bg-transparent mobile-center">
      <div class="xl:w-1/2 xl:p-0 w-full p-8 items-center my-auto">
        <h1 class="md:block hidden text-5xl font-bold mb-2 text-center">
          {{ $t('welcome to').capitalize() }}</h1>

        <img src="../../assets/logo_fluitendleren_liggend.png" alt="Fluitend Leren Logo" class="mx-auto mb-2 w-1/2">
        <h2 class="text-2xl mb-8 text-center text-secondary">
          {{ $t('login').capitalize() }}</h2>

        <form @submit.prevent="handleLogin()" class="flex flex-col">

          <input-component v-model="useAuthStore.form.email" input-type="email" placeholder="email" inputColor="primary"
                           class="w-full mt-5" :required="true"/>
          <input-component v-model="useAuthStore.form.password" input-type="password" placeholder="password"
                           inputColor="secondary" class="w-full mt-5" :required="true"/>

          <div class="mt-5">
            <div id="recaptcha" class="g-recaptcha"></div>
          </div>

          <div class="text-red-500" v-if="notification.recaptchaMessage">
            {{ $t(notification.recaptchaMessage).capitalize() }}.
          </div>

          <button-component type="submit" class="mx-auto w-full md:w-full my-4" buttonText="login"
                            :is-loading="useAuthStore.isLoading" buttonColor="default" :always-show-text="true"
                            outlined/>
        </form>

        <RouterLink to="/register" class="w-full">
          <button-component type="button" class="mx-auto w-full md:w-full opacity-100 mb-4"
                            buttonText="try our demo!" :disabled="useAuthStore.isLoading" buttonColor="cta"
                            :always-show-text="true"/>
        </RouterLink>

        <RouterLink to="/recover" class="text-center block mx-auto mb-4 text-gray-500">
          {{ $t('forgot password').capitalize() }}?
        </RouterLink>
      </div>

      <div class="mt-auto mb-4 px-4 flex justify-start space-x-4 w-full">
        <button @click="showPrivacyPolicy = true" class="md:text-md text-sm text-gray-500 underline">
          {{ $t('privacy policy').capitalize() }}
        </button>

        <button @click="showTermsOfService = true" class="md:text-md text-sm text-gray-500 underline">
          {{ $t('terms and conditions').capitalize() }}
        </button>
      </div>
    </div>

    <div class="hidden xl:block w-full h-full fixed xl:-right-32 lg:-right-72 top-0 z-0">
      <img src="../../assets/thumbnail_fluitendleren.png" alt="Girl Holding Book"
           class="w-full h-full object-cover object-right">
    </div>

    <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false"/>
    <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false"/>

  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import {useRouter} from 'vue-router';
import {canUseCookies, hasDeniedCookies, hasEssentialCookies} from "@/auth.js";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";
import {i18n} from "@/i18n";
import {appStore} from "@/views/app.store";

export default {
  name: 'LoginComponent',

  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    const notification = ref({
      message: '',
      type: 'error',
    });
    const showPrivacyPolicy = ref(false);
    const showTermsOfService = ref(false);

    const recaptchaVerified = ref(false);
    const recaptchaToken = ref('');
    const recaptchaWidgetId = ref(null);

    /**
     * Callback function when reCAPTCHA is verified.
     * @param {string} token - The reCAPTCHA token.
     */
    const onRecaptchaVerified = (token) => {
      recaptchaToken.value = token;
      recaptchaVerified.value = true;
    };

    /**
     * Loads the reCAPTCHA script.
     * @returns {Promise<void>}
     */
    const loadRecaptchaScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById('recaptcha-script')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        script.onload = resolve;
        script.onerror = reject;
      });
    };

    /**
     * Checks if reCAPTCHA is loaded.
     * @returns {Promise<void>}
     */
    const checkRecaptchaLoaded = () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (window.grecaptcha && typeof window.grecaptcha.render === 'function') {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    };

    /**
     * Renders the reCAPTCHA widget.
     */
    const renderRecaptcha = () => {
      if (window.grecaptcha && recaptchaWidgetId.value === null) {
        recaptchaWidgetId.value = window.grecaptcha.render('recaptcha', {
          'sitekey': '6Lfz1CsqAAAAAGwx8uo-2sd_Q-V0EU2TSNOrtPQJ',
          'callback': onRecaptchaVerified,
        });
      }
    };

    /**
     * Resets the reCAPTCHA widget.
     */
    const resetRecaptcha = () => {
      if (window.grecaptcha && recaptchaWidgetId.value !== null) {
        recaptchaVerified.value = false;
        window.grecaptcha.reset(recaptchaWidgetId.value);
      }
    };

    /**
     * Initializes the reCAPTCHA widget.
     */
    const initializeRecaptcha = () => {
      loadRecaptchaScript()
          .then(() => checkRecaptchaLoaded())
          .then(() => renderRecaptcha())
          .catch((error) => console.error('Failed to load reCAPTCHA script', error));
    };

    onMounted(() => {
      initializeRecaptcha();
    });

    return {
      useAppStore,
      useAuthStore,
      router,
      notification,
      recaptchaVerified,
      recaptchaToken,
      resetRecaptcha,
      showPrivacyPolicy,
      showTermsOfService,
    };
  },

  methods: {
    /**
     * Handles the login process.
     * @returns {Promise<void>}
     */
    async handleLogin() {
      this.useAuthStore.isLoading = true;

      if (!this.recaptchaVerified) {
        this.notification.recaptchaMessage = 'please complete the reCAPTCHA';
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
        return;
      }

      if (canUseCookies() || hasEssentialCookies()) {
        await this.useAuthStore.login(this.recaptchaToken).then(() => {
          this.router.push({name: 'Dashboard'});
        }).catch(error => {
          this.notification.message = error.response.data.message;
          this.notification.type = 'error';
        }).finally(() => {
          this.useAuthStore.isLoading = false;
          this.resetRecaptcha();
        });
      } else if (hasDeniedCookies()) {
        this.notification.message = i18n.t('you have denied cookies. To login, please enable cookies again').capitalize();
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
      } else {
        this.notification.message = i18n.t('you must accept cookies to log in').capitalize();
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
      }
    },
  },

  components: {InputComponent, ButtonComponent, PrivacyPolicy, TermsOfService}
}
</script>

<style scoped>
@media (max-width: 640px) {
  .mobile-center {
    justify-content: center;
    height: calc(100% - 40px);
  }
}
</style>