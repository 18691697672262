<template>
  <div
      v-if="useLibraryStore.showEditBookModal"
      class="absolute md:fixed z-50 inset-0 bg-gray-600 bg-opacity-50 bottom-0 overflow-y-auto md:h-full flex justify-center items-center"
      @click.self="closeModal">

    <div
        class="relative mx-auto p-5 border md:w-3/5 w-full overflow-y-auto shadow-lg rounded-md bg-white md:h-[calc(115vh-200px)]">
      <div class="absolute top-4 right-4 transform">
        <button @click="closeModal"
                class="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-pink-500 hover:bg-pink-100 border-2 border-white">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <h3 class="text-xl leading-6 font-bold text-gray-900" v-if="!useLibraryStore.editMode">
        {{ $t('create new lesson').capitalize() }}
      </h3>
      <h3 class="text-xl leading-6 font-bold text-gray-900" v-else>
        {{ $t('update lesson').capitalize() }}
      </h3>
      <hr class="mb-4">
      <form @submit.prevent="submitBook(useLibraryStore.form.uuid)" class="pt-2 grid grid-cols-2 gap-4">

        <select-component class="w-full col-span-2 font-bold" label="Is it a demo lesson?" inputColor="secondary"
                          v-model="useLibraryStore.form.is_demo" :options="isDemoOptions" required/>

        <input-component input-type="text" placeholder="lesson name" v-model="useLibraryStore.form.name"
                         inputColor="primary" class="w-full mb-4 col-span-2 font-bold" label="lesson name"/>
        <input-component input-type="text" placeholder="lesson code" v-model="useLibraryStore.form.lesson_code"
                         inputColor="primary" class="w-full mb-4 col-span-2 font-bold" label="lesson code" required/>

        <h3 class="font-bold text-gray-900 col-span-2">{{ $t('categories').capitalize() }}</h3>
        <div class="border-2 rounded-3xl border-[#ECEAE3] col-span-2 p-4">
          <div class="grid lg:grid-cols-4 grid-cols-3 gap-4 lg:text-sm text-xs">
            <div v-for="category in useLibraryStore.categories" :key="category">
              <p class="font-semibold text-blue-400">{{ category.name }}</p>
              <div v-if="category.subcategories.length">
                <checkbox-component
                    v-for="subcategory in category.subcategories"
                    :field-id="subcategory.id"
                    :label="subcategory.name"
                    :key="subcategory"
                    v-model="useLibraryStore.form.category_ids"
                    :checked="useLibraryStore?.form?.category_ids?.includes(subcategory.id)"
                    @update:checked="checkCategory(subcategory.id, $event)"
                ></checkbox-component>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-2 grid grid-cols-2 gap-1">
          <input-component input-type="url" placeholder="Enter YouTube link" v-model="useLibraryStore.form.url"
                           inputColor="primary" class="w-full font-bold col-span-2 mr-1" label="Lesson link"/>

          <div class="flex space-x-1 col-span-2">
            <div class="w-full">
              <label class="ml-2 block text-gray-700 text-sm font-bold mb-2 " for="lessonFile">
                {{ useLibraryStore.form.file_name || $t('choose lesson').capitalize() }}
              </label>
              <input id="lessonFile" type="file" accept="application/pdf" @change="handleFileUpload" class="hidden"/>
              <label
                  for="lessonFile"
                  class="ml-2 cursor-pointer py-2 px-4 bg-action text-white rounded-md shadow-sm hover:bg-action-light"
              >
                {{ $t('insert lesson').capitalize() }}
              </label>
            </div>

            <div class="w-full">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="thumbnail">
                {{ useLibraryStore.form.image_name || $t('choose thumbnail').capitalize() }}
              </label>
              <input id="thumbnail" type="file" accept="image/jpeg" @change="handleImageUpload" class="hidden"/>
              <label
                  for="thumbnail"
                  class="ml-0 cursor-pointer py-2 px-4 bg-action text-white rounded-md shadow-sm hover:bg-action-light"
              >
                {{ $t('add thumbnail').capitalize() }}
              </label>
            </div>
          </div>
        </div>

        <textarea-component placeholder="Description" v-model="useLibraryStore.form.description" inputColor="secondary"
                            class="w-full col-span-2 mb-4 font-bold" label="Description" required/>

        <button-component buttonText="toevoegen" :always-show-text="true" buttonColor="action" class="bottom-0 mb-4"
                          :is-loading="useLibraryStore.isLoading"/>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import SelectComponent from "@/components/forms/SelectComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";

export default {
  name: 'CreateBookComponent',
  components: {
    CheckboxComponent,
    TextareaComponent,
    SelectComponent,
    ButtonComponent,
    InputComponent
  },

  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    };
  },

  data() {
    return {
      isDemoOptions: [
        {value: 1, text: 'yes'},
        {value: 0, text: 'no'}
      ]
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    /**
     * Validates if the provided URL is a valid YouTube URL.
     * @param {string} url - The URL to validate.
     * @returns {boolean} True if the URL is valid, false otherwise.
     */
    isValidYouTubeUrl(url) {
      if (!url) return true;
      const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return pattern.test(url);
    },

    /**
     * Updates the selected categories based on the checkbox state.
     * @param {number} categoryId - The ID of the category.
     * @param {boolean} checked - The state of the checkbox.
     */
    checkCategory(categoryId, checked) {
      let storeSelectedCategories = this.useLibraryStore.form.category_ids;
      if (checked) {
        storeSelectedCategories.push(categoryId);
      } else {
        storeSelectedCategories.splice(storeSelectedCategories.indexOf(categoryId), 1);
      }
    },

    /**
     * Handles the image file upload and updates the form data.
     * @param {Event} event - The file input change event.
     */
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.useLibraryStore.form.image_name = file.name;
        this.useLibraryStore.form.image = file;
      } else {
        this.useLibraryStore.form.image_name = '';
        this.useLibraryStore.form.image = null;
      }
    },

    /**
     * Handles the PDF file upload and updates the form data.
     * @param {Event} event - The file input change event.
     */
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.useLibraryStore.form.file_name = file.name;
        this.useLibraryStore.form.file = file;
      } else {
        this.useLibraryStore.form.file_name = '';
        this.useLibraryStore.form.file = null;
      }
    },

    /**
     * Fetches all categories from the store.
     */
    async getCategories() {
      await this.useLibraryStore.fetchAllCategories();
    },

    /**
     * Submits the book form data to create or update a book.
     */
    async submitBook() {
      if (!this.isValidYouTubeUrl(this.useLibraryStore.form.url)) return;

      if (this.useLibraryStore.editMode) {
        await this.useLibraryStore.updateBook();
      } else {
        await this.useLibraryStore.createBook();
      }

      this.useLibraryStore.lastFetched = null;
      await this.getCategories();
      await this.useLibraryStore.fetchAllBooks();
    },

    /**
     * Closes the modal.
     */
    closeModal() {
      this.useLibraryStore.showEditBookModal = false;
    }
  }
}
</script>